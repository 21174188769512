@import '../../../../assets/styles/variable';

.bp__gic__profile-popup {
  .e_dialog__header {
    color: $light-color2;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    padding: 16px;
  }

  .e_dialog__body {
    .e_dialog__body__wrapper {
      padding: 24px 16px;

      .gic__title {
        color: #a2abb3;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin: 0;
        margin-bottom: 4px;
      }

      .gic__value {
        color: #a2abb3;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 16px;
        margin-top: 0;

        .value {
          color: $light-color2;
          margin-right: 4px;
        }
      }

      .gic__description {
        color: $light-color2;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-top: 0;
      }

      .gic__contact__installer {
        background-color: $light-bg2;
        border-radius: 8px;
        margin-bottom: 16px;
        padding: 12px;

        .gic__contact-installer__info {
          align-items: flex-start;
          color: $light-color2;
          display: flex;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          margin: 0;

          .text-link {
            color: $color-blue;
            font-weight: 500;
          }

          .icon-info-alert {
            height: 24px;
            margin-right: 8px;
            width: 24px;
          }
        }
      }

      .bp__radio-checkbox__button {
        align-items: center;
        display: flex;

        .bp__radio-checkbox__description {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          margin-left: 8px;
        }
      }
    }
  }
}

.dark-theme {
  .e_dialog__profile.bp__gic__profile-popup, .e_dialog__battery.bp__gic__profile-popup {
    .e_dialog__body {
      .e_dialog__body__wrapper {
        .gic__value {
          .value {
            color: #fff;
          }
        } 

        .gic__description {
          color: #a2abb3;
        }
      }

      .gic__contact__installer {
        background-color: $light-color2;

        .gic__contact-installer__info {
          color: #fff;

          .icon-info-alert {
            svg {
              path {
                fill: #fff;
              }
            }
          }
        }
      }

      .bp__radio-checkbox__button { 
        .bp__radio-checkbox__description {
          color: #a2abb3;
        }
      }
    }
  }
}
