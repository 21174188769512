@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/mixin';

header {
  background-color: $color-blue;
  height: $header-height;

  .bp__header {
    &--wrapper {
      padding: calc(14px + env(safe-area-inset-top)) 20px 14px;
    }
  }

  .atom-design--t3 {
    gap: 0 8px;
    grid-template-columns: 12px auto auto;
    margin: 0;

    .com__ad__icon {
      cursor: pointer;

      .bp__icon {
        &.icon-prev-header {
          display: flex;

          svg {
            margin-top: -2px;
          }
        }
      }

      .icon-left-arrow {
        height: 20px;
        width: 20px;
      }
    }

    .com__ad__details {
      display: grid;
      height: 36px;

      .com__ad--with-subtitle {
        .com__ad--title {
          line-height: normal;
          margin-top: 0;
        }
      }
    }

    .com__ad--title {
      color: $light-color1;
      font-size: 16px;
      font-weight: 500;
      line-height: 36px;
      @include text-ellipse;
    }

    .com__ad--sub-title {
      color: $light-color1;
      font-weight: 400;
      @include text-ellipse;
    }

    .com__ad__action {
      color: $light-color1;
      cursor: pointer;
    }
  }
}

.source-enho,
.source-enlm,
.source-enlm-web,
.source-enla,
.source-enla-web {
  #bp-page-profile-details,
  #bp-page-battery-details {
    header {
      .bp__header {
        &--wrapper {
          &.atom-design--t3 {
            grid-template-columns: auto auto;

            .com__ad__icon {
              display: none;
            }
          }
        }
      }
    }
  }
}

.source-itk,
.source-itk-mobile {
  header {
    height: $itk-header-height;

    .atom-design--t3 {
      .com__ad__details {
        height: 20px;
      }
    }

    .bp__header {
      &--wrapper {
        padding: 18px 16px;
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Web View
 * *********************** *********************** *********************** */

.web-view {
  header {
    .atom-design--t3 {
      .com__ad--title {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}

.source-enho-web {
  header {
    background-color: $light-bg1;
    height: auto;
    padding: 0;
    position: fixed;
    width: 100%;
    z-index: 100;

    .bp__header {
      &--wrapper {
        align-items: center;
        display: flex;
        height: $web-header-height;
        padding: 0 32px;
      }
    }

    .atom-design--t3 {
      .com__ad__icon {
        .icon-left-arrow {
          height: 24px;
          width: 24px;

          svg {
            polygon {
              fill: #545456;
            }
          }
        }
      }

      .com__ad--title {
        color: $dark-slider-track;
        font-size: 30px;
        overflow: initial;
      }

      .com__ad__details {
        display: grid;
        height: 36px;
        width: 100%;
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Theme
 * *********************** *********************** *********************** */

.dark-theme {
  header {
    background-color: $dark-bg1;
  }

  &.source-enho-web {
    header {
      background-color: $dark-bg1;

      .atom-design--t3 {
        .com__ad__icon {
          .icon-left-arrow {
            svg {
              polygon {
                fill: #545456;
              }
            }
          }
        }

        .com__ad--title {
          color: $dark-color1;
        }
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */

.orange-theme {
  header {
    background-color: $color-orange;

    .atom-design--t3 {
      .com__ad__icon {
        .icon-left-arrow {
          svg {
            polygon {
              fill: #fff;
            }
          }
        }
      }

      .com__ad--title {
        color: $dark-color1;
      }

      .icon-close {
        svg {
          path {
            fill: #fff;
            stroke: none;
          }
        }
      }
    }
  }
}
