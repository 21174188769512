@import '../styles/variable';

.bp{
  &__icon{
    svg{
      height: inherit;
      width: inherit;
    }

    &.icon-info{
      cursor: pointer;
    }

    &.icon-arrow-right{
      cursor: pointer;
      height: 12px;

      svg{
        transform: rotate(180deg);

        polygon{
          fill: $light-color6;
          stroke: $light-color6;
        }
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Web View
 * *********************** *********************** *********************** */

 .web-view {
  .bp{
    &__icon{
      &.icon-info{
        height: 16px;
        width: 16px;
      }

      &.icon-arrow-right{
        height: 16px;
      }
    }
  }
 }

/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */

.orange-theme{
  .icon-loader-v1{
    path{
      fill: $color-orange;
    }
  }

  .icon-loader-v3{
    circle{
      stroke: $color-orange;
    }
  }

  .icon-loader-v4{
    circle{
      stroke: $color-orange;
    }
  }

  .icon-external-link{
    g {
      fill: $color-orange;

      path {
        stroke: $color-orange;
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

 .dark-theme{
  .bp{
    &__icon{
      svg{
        &.icon-dark-fill-white, .icon-dark-fill-white{
          path{
            fill: $dark-color1;
          }
        }
      }

      &.icon-info{
        svg{
          path{
            fill: $light-color6;
          }
        }
      }

      &.icon-close{
        svg{
          path{
            stroke: none;
          }
        }
      }
  
      &.icon-clock-circular{
        svg{
          circle, path{
            stroke: $dark-color1;
          }
        }
      }

      &.icon-itk-help, #itk-help-icon{
        path{
          fill: $dark-color1;
        }
      }

      &.icon-arrow-right{
        svg{
          polygon{
            fill: $dark-color1;
            stroke: $dark-color1;
          }
        }
      }
    }
  }
}