@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/extend';

.ib{
  &__btn{
    border: 0;
    border-radius: 0 0 10px 10px;
    border-top: 1px solid #7b87934d;
    color: $light-color1;
    cursor: pointer;
    display: inherit;
    height: 45px;
    outline: 0;
    width: 100%;
    @extend %bg-blue;

    &--loading{
      align-items: center;
      background-color: transparent;
      color: inherit;
      display: inherit;
      display: flex;
      justify-content: center;
      padding: 0;
      pointer-events: none;
      width: 100%;

      .bp__icon{
        svg{
          height: 18px;
        }
      }
    }

    &--cancel{
      background-color: transparent;
      border-top: 1px solid #ddd;
      color: $color-blue;
      font-weight: 500;
    }
  }
}

button{
  &:disabled{
    &.ib{
      &__btn{
        color: $light-color1;

        &--loading{
          background-color: transparent !important;
        }
      }
    }
  }
}


/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme{
  .ib{
    &__btn{
      &--cancel{
        border-color: $dark-border;
      }
    }
  }
}


/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */

 .orange-theme{
  .ib{
    &__btn{
      background-color: $color-orange;
      color: $light-color1 !important;

      &--disabled{
        background-color: $button-bg-diabled;
      }

      &--cancel{
        background-color: transparent;
        color: $color-orange !important;
      }

      &--loading{
        background-color: transparent;
      }

      &--save{
        background-color: $color-orange !important;
        color: $light-color1 !important;
      }
    }
  }
}