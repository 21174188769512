@import '../../../../assets/styles/variable';

.sph__choose-right-profile-popup{
  .e_dialog{
    &__wrapper{
      height: calc(100% - 90px);
    }
  }
}

.learn-more-about-profiles {
  overflow: hidden;

  .bp {
    &__has__tutorial {
      .bp__contents {
        &__tabs {
          position: relative;
          z-index: 10;
        }
      }
    }

    &__wrapper {
      background-color: #fafafb;
      height: inherit;
    }

    &__contents {
      height: 100vh;

      &__tabs {
        background-color: #fff;
        border: none;
        border-bottom: .5px solid rgb(123 135 147 / 30%);
        display: flex;
        padding: 2px 12px;

        span {
          align-items: center;
          background-color: #E9E9E9;
          border-radius: 100px;
          color: #545456;
          display: flex;
          font-size: 12px;
          height: 30px;
          justify-content: center;
          line-height: 14px;
          margin: 10px 2px;
          padding-top: 2px;
          text-align: center;
          white-space: break-spaces;
          width: 100%;
        }

        .active {
          background-color: #01b5de;
          color: #fff !important;
        }
      }
    }

    &__has__evse {
            height: calc(100vh - 90px);
            overflow: auto;
    }

    &__contents__nav {
      background: linear-gradient(0deg,#fff 20%,hsl(0deg 0% 100% / 0%));
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;

      ul {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0;
      }

      li {
        background-color: rgb(1 180 222 / 30%);
        border-radius: 50%;
        cursor: pointer;
        display: inline-block;
        height: 10px;
        margin: 4px;
        position: relative;
        width: 10px;
      }

      &__active {
        background-color: $color-blue !important;

        &::before {
          border: 1px solid #06b5de;
          border-radius: 50%;
          content: "";
          display: inline-block;
          height: 16px;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 16px;
        }

      }

    }




    &__profile {
      &__wrapper {
        background-color: #fff;
        padding: 15px 20px;

        &__heading {
          color: #545456;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }

        &__ei {
          align-items: center;
          color: #595959;
          display: flex;
          font-size: 12px;
          font-weight: 400;
          height: 40px;
          justify-content: space-between;
          line-height: 14px;

          &__title {
            background-color: #f0f0f0;
            border-radius: 78px;
            padding: 6px 10px;

            &__disabled {
              color: #7B8793;
            }

            &__enabled {
              color: #F37320;
            }
          }
        }

        &__status {
          align-items: center;
          color: #6C7074;
          display: flex;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          margin-top: 10px;

          &__battery {
            align-items: center;
            display: flex;

            &__text {
              height: 13px;
            }

            span {
              display: flex;
              margin-right: 4px;

              svg {
                height: 16px;
                width: 16px;
              }
            }
          }
        }
      }
    }

    &__tutorial {
      background-color: rgb(0 0 0 / 95%);
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: fixed;
      z-index: 1;

      &__wrapper {
        left: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 200px;

        span {
          align-items: center;
          display: flex;
          justify-content: center;
        }

      }

      &__desc {
        color: #FFF;
        font-size: 14px;
        line-height: 17px;
        margin: 15px 20px 20px;
      }

      button {
        background: transparent;
        border: 1px solid;
        border-radius: 4px;
        color: #FFF;
        font-size: 16px;
        outline: none;
        padding: 10px;
        width: 135px;
      }

      &__swipe-left-right {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 11;

        &__wrapper {
          margin-top: 0;
          text-align: center;

          span {
            display: flex;
            justify-content: center;
          }
        }

        .icon {
          height: 55px;
        }
      }
    }

    &__desc {
      background-color: #FAFAFB;
      font-size: 12px;
      letter-spacing: normal;
      line-height: 1.29;
      margin-bottom: 100px;
      padding: 25px 25px 2px;

      &__title {
        color: #545456;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      ul {
        padding-left: 15px;
      }

      li {
        color: #6B7073;
        list-style-type: disc;

        &:not(:first-child) {
          margin-top: 15px;
        }
      }
    }
  }
}

.bp__profile__wrapper__status__grid {
  align-items: center;
  display: flex;
  margin-left: 20px;

  &__indicator {
    margin-right: 5px;

    span {
      height: 16px;
      margin-top: -1px;
      width: auto;

      svg {
        height: inherit;
        width: inherit;
      }
    }
  }

  &__text {
    height: 13px;
  }
}

/****************************************************************
Dark theme
****************************************************************/

.dark-theme {
  .learn-more-about-profiles {
    .bp {
      &__wrapper {
        background-color: #313131;
      }

      &__contents {
        &__tabs {
          background-color: $dark-bg2;
        }
      }

      &__profile {
        &__wrapper {
          background-color: $dark-bg2;

          &__ei {
            &__title {
              background-color: #313131;
              color: $light-border;
            }
          }

          &__heading {
            color: $light-bg3;
          }

          &__status{
            &__battery{
              &__text{
                color: $light-border
              }
            }

            &__grid__indicator{
              path:nth-child(1){
                fill : $light-border;
              }
            }
          }
        }
      }

      &__desc {
        background-color: #313131;

        &__title {
          color: #F8F8FF;
        }

        li {
          color: $light-border;
        }


      }

      &__contents__nav {
        background: linear-gradient(0deg,#262627 20%,transparent);
      }
    }
  }

  .bp__profile__wrapper__status__grid {
    &__text {
      color: $light-border;
    }
  }
}

/* ----------------------------------------------------------------
Media query
---------------------------------------------------------------- */

@media screen and (width <= 375px) {
  .learn-more-about-profiles {
    .bp {
      &__contents {
        height: calc(100vh - 90px);
        overflow: auto;
      }

      &__contents__tabs span {
        min-width: 100px;
      }
    }
  }

}




/* =================================================================
Web View
+---------------------------------------------------------------- */

.web-view {
  .sph__choose-right-profile-popup{
    .e_dialog {
      &__wrapper {
        height: auto;
        width: 650px;
      }
    }
  }

  .learn-more-about-profiles {
    .bp__profile {
      display: flex;
      width: 100%;

      &__wrapper {
        background-color: transparent;
        padding: 25px 30px;
        width: 50%;
      }
    }

    .bp__desc{
      width: 50%;
    }

    .bp__contents__tabs span {
      min-width: 200px;
    }

    .bp__contents {
      height: 500px;
    }
  }
}