@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/extend';

#bp-charge-battery-from-grid {
  color: $light-color2;

  .cbfg {
    &__title-toggle {
      display: grid;
      gap: 16px;
      grid-auto-flow: column;
      justify-content: space-between;
    }

    &__status-info {
      align-items: center;
      display: grid;
      grid-auto-flow: column;
      height: 20px;
      width: fit-content;
    }

    &__status-icon {
      margin-right: 5px;

      svg {
        height: 15px;
        width: 15px;
      }

      .icon-clock-circular {
        path {
          @extend %fill-blue;
        }
      }
    }

    &__status-text {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      margin-right: 5px;

      &.cfg__profile--savings {
        .bp__status-text--enabled {
          color: #f9b98f;
        }
      }
    }

    &__info {
      margin-top: -2px;
    }

    &__grid-tied,
    &__grid-agnostic {
      font-size: 12px;
      line-height: 16px;
      margin-top: 12px;
    }

    &__grid-tied {
      input[type='checkbox'] {
        margin: 3px 10px 0 0;
      }
    }

    &__grid-agnostic {
      .cbfg__ga {
        &__below-reserve {
          pointer-events: none;

          &-text {
            color: #545456;
            margin-top: 2px;
          }
        }

        &__100 {
          font-weight: 400;
          margin-top: 17px;

          &-text {
            color: #545456;
            margin-top: 2px;
          }
        }
      }

      input[type='checkbox'] {
        height: 16px;
        margin: 0 8px 0 0;
        width: 16px;
      }

      input[type='checkbox']:checked::before {
        height: 16px;
        width: 16px;
      }

      &.oldcbfg__oldgrid-agnostic {
        margin-top: 16px;

        .cbfg {
          &__ga {
            &__wrapper {
              background-color: #f5f5f8;
              border-radius: 10px;
              padding: 16px;

              label {
                display: flex;
              }
            }
          }

          &__time-selection {
            padding: 0;

            &--disabled {
              opacity: 0.5;
              pointer-events: none;

              .cbfg {
                &__time-selection__value {
                  color: #01b5de;
                }
              }
            }
          }
        }

        .bp__icon {
          margin-bottom: 4px;
        }
      }
    }

    &__time-selection {
      align-items: center;
      background-color: #f5f5f8;
      border-radius: 8px;
      display: flex;
      margin-top: 16px;
      padding: 14px;

      .bp__icon {
        margin-bottom: 2px;
        margin-right: 10px;
      }

      &__label {
        color: $light-color2;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin-right: 5px;
      }

      &__value {
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        @extend %color-blue;
      }

      &--start {
        display: flex;
        margin-right: 24px;
      }

      &--end {
        display: flex;
      }

      &--disabled {
        .cbfg {
          &__time-selection {
            &__value {
              color: $light-color5;
              cursor: auto;
            }
          }
        }
      }
    }

    &__schedule-selection {
      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &__time-error {
      color: #e3655e;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin-top: 16px;
    }
  }

  .cbfg__olduibutton {
    border: 1px solid #00b6de;
    border-radius: 2em;
    height: 40px;
    margin: 0 auto;
    padding: 10px 0;
    width: 240px;
  }

  .lock-cfg-toggle {
    .react-switch-bg {
      background: $light-bg4 !important;
    }

    .react-switch-handle {
      background: $light-color9 !important;
    }

    &.bp_ts_on.bp_ts_disabled {
      .bp_ts_handler {
        opacity: 1 !important;
      }
    }
  }
}

.cbfg {
  &__popup__wrapper {
    padding: 20px 20px 25px;
  }

  .e_dialog__content {
    font-weight: 400;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__info-popup {
    .bp__status-text--disabled {
      color: $light-color2;
    }
  }

  &__itc-popup {
    .e_dialog__content {
      &.bp__radio-checkbox__button {
        display: flex;
        font-weight: 500;

        input[type='checkbox'] {
          margin: 3px 10px 0 0;
        }

        .bp__radio-checkbox__description {
          width: 80%;
        }
      }
    }

    .bp {
      &__action-button {
        &:last-child {
          background-color: transparent !important;
          color: #00b6de;
          font-weight: 500;
        }

        &.bp__ab--disabled {
          color: $disabled !important;
        }
      }
    }
  }

  &__restrict-popup {
    .e_dialog__wrapper {
      .e_dialog__body {
        .cbfg__popup__wrapper {
          padding: 30px 20px;

          .icon-info-alert {
            display: flex;
            justify-content: center;
          }

          .e_dialog__content {
            margin-top: 30px;
            text-align: center;

            .contact_support_link {
              color: #01b5de;
              text-decoration: underline;
            }
          }
        }
      }

      .e_dialog__footer__wrapper {
        justify-content: center;
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme {
  #bp-charge-battery-from-grid {
    color: $dark-color2;

    .cbfg {
      &__time-selection {
        background-color: #262627;

        &__label {
          color: $dark-color1;
        }
      }
    }

    .oldcbfg__oldgrid-agnostic {
      .cbfg {
        &__ga__wrapper {
          background-color: #262627;

          .cbfg__ga__below-reserve {
            input[type='checkbox']:checked:disabled::before {
              background-color: #9e9f9f;
            }

            &-text {
              color: #fff;
            }
          }

          .cbfg__ga__100 {
            input[type='checkbox']:checked:disabled::before {
              background-color: #9e9f9f;
            }

            &-text {
              color: #fff;
            }
          }
        }
      }
    }

    .lock-cfg-toggle {
      .react-switch-bg {
        background: $dark-color2 !important;
      }

      .react-switch-handle {
        background: $dark-color4 !important;
      }
    }
  }

  .cbfg {
    &__info-popup {
      .bp__status-text--disabled {
        color: $dark-color1;
      }
    }

    &__restrict-popup {
      .e_dialog__wrapper {
        .e_dialog__body {
          .cbfg__popup__wrapper {
            padding: 30px 20px;

            .icon-info-alert {
              display: flex;
              justify-content: center;

              svg {
                path {
                  fill: #fff;
                }

                path:not(:first-child) {
                  fill: #fff;
                  stroke: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

/****************************************************************************
Web view
****************************************************************************/

.web-view {
  #bp-charge-battery-from-grid {
    .cbfg {
      &__title-toggle {
        .cbfg__toggle {
          .cbfg__status__toggle-container {
            display: flex;

            .cbfg__status-text {
              margin-right: 12px;
              margin-top: 1px;
            }
          }
        }

        .bp__card-view--title {
          align-items: center;
          display: flex;

          span {
            .bp__icon {
              svg {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }

  .cbfg {
    &__itc-popup {
      .bp {
        &__action-button {
          &:last-child {
            background-color: #00b6de !important;
          }

          &.bp__ab--disabled {
            color: $disabled !important;
          }
        }
      }
    }

    &__restrict-popup {
      .e_dialog__wrapper {
        .e_dialog__footer {
          padding: 0 20px 20px;
        }
      }
    }
  }
}

/* ----------------------------------------------------------------
Orange Theme
---------------------------------------------------------------- */

.orange-theme {
  #bp-charge-battery-from-grid {
    .cbfg {
      &__time-selection {
        &__value {
          color: $color-orange !important;
        }
      }
    }
  }
}
