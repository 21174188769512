@import '../../../../assets/styles/variable';

#bp-page-ev-schedule{
  .etus__wrapper{
    padding: 20px;
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

 .dark-theme {
  #bp-page-ev-schedule{
    // TODO
  }
 }

/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */

.orange-theme{
  #bp-page-ev-schedule{
    // TODO
  }
}
