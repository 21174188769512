@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/mixin';

.edit-after-peak-hours{
  margin-top: 16px;

  &.atom-design--t1{
    .com__ad__title{
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
    }
  }
}

/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */

.orange-theme{
  .edit-after-peak-hours{
    // TODO
  }
}

/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */

 .web-view{
  .edit-after-peak-hours{
    &.atom-design--t1{
      .com__ad__title{
        font-size: 15px;
      }

      .com__ad__value{
        .ts__status-text {
          font-size: 16px;
        } 
      }
    }
  }
}