@keyframes kf-slide-up {
  0% {
    top: '100%';
  }

  100% {
    top: 0;
  }
}

@keyframes kf-slide-down {
  0% {
    top: 0;
  }

  100% {
    top: '100%';
  }
}

@keyframes shimmer {
  100% {mask-position:left}
}