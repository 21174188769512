/* Font Sequance */
$fonts-seq: 'Visuelt', 'Roboto', 'Helvectica Neue', helvetica, arial, sans-serif;
$font-tstar-seq: 't-star', 'Visuelt', 'Roboto', 'Helvectica Neue', helvetica, arial, sans-serif;
$header-height: calc(64px + env(safe-area-inset-top));
$itk-header-height: 0; // 56px
$web-header-height: 97px;
$footer-height: 9vh;
$radio-checkbox-size: 20px;

/* Colors */
$color-blue: #01b5de;
$color-orange: #f37321;
$color-error: #f37320;
$button-bg-diabled: #6b7073;
$disabled: #6b7073;
$color-red-error: #de3c41;

/* Light */
$light-color1: #fff;
$light-color2: #545456;
$light-color3: #6f6f8c;
$light-color4: #b2b5b6;
$light-color5: #989696;
$light-color6: #6b7073;
$light-color7: #555557;
$light-color8: #a9a9aa;
$light-color9: #99e1f2;
$light-bg1: #fff;
$light-bg2: #f6f7fb;
$light-bg3: #f8f8ff;
$light-bg4: #dfdfdf;
$light-border: #d8dcde;
$light-overlay: rgb(0 0 0 / 50%);

/* Dark */
$dark-color1: #fff;
$dark-color2: #a9a9aa;
$dark-color3: #000;
$dark-color4: #175f70;
$dark-bg1: #333;
$dark-bg2: #262627;
$dark-bg3: #a2a6a9;
$dark-border: #3b3e41;
$dark-overlay: rgb(0 0 0 / 50%);

/* Slider */
$slider-track: #e9e9e9;
$slider-track-disabled: #707070;
$slider-track-shadow: #00000029;
$slider-disable-track: #babec3;
$dark-slider-track: #545456;

/* ITK theme */
$itk-orange-light: #fffbf3;
$itk-orange-medium: #ffb687;
