@import './variable';
@import './extend';

.bp__radio-checkbox__button {
  input[type='radio'],
  input[type='checkbox'] {
    appearance: none;
    border: 1.5px solid #9e9f9f;
    cursor: pointer;
    height: $radio-checkbox-size;
    margin: 0;
    outline: none;
    width: $radio-checkbox-size;

    &:hover {
      @extend %border-blue;
    }

    &:disabled {
      background-color: #e7ebef;
      border-color: #9e9f9f;
      pointer-events: none;

      &:hover {
        border-color: #9e9f9f;
      }
    }

    &:checked {
      @extend %border-blue;

      &:disabled {
        &::before {
          background-color: #9e9f9f;
        }
      }
    }
  }

  input[type='radio'] {
    border-radius: 50%;
    position: relative;

    &:checked {
      &::before {
        background-color: $color-blue;
        border-radius: 50%;
        content: '';
        display: block;
        font: inherit;
        height: calc($radio-checkbox-size - 8px);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: calc($radio-checkbox-size - 8px);
      }
    }
  }

  input[type='checkbox'] {
    border-radius: 2px;

    &:checked {
      border: none;

      &::before {
        background: $color-blue;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="9.822" height="7.077" viewBox="0 0 9.822 7.077"><path id="Fill_1" data-name="Fill 1" d="M14.155,7.773l-.635.635-2.774,2.774q-.872.869-1.74,1.74a.986.986,0,0,1-1.372,0q-.172-.172-.339-.341L4.889,10.169A.97.97,0,1,1,6.261,8.8q.172.172.339.341c.574.574,1.149,1.152,1.72,1.725L11.044,8.14l1.74-1.74a.97.97,0,1,1,1.371,1.372Z" transform="translate(-4.612 -6.123)" fill="%23fff"/></svg>');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 12px 10px;
        border-radius: 2px;
        content: '';
        display: block;
        font: inherit;
        height: $radio-checkbox-size;
        margin: auto;
        padding: 0;
        width: $radio-checkbox-size;
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme {
  .bp__radio-checkbox__button {
    input[type='checkbox'] {
      &:checked {
        &::before {
          background: $color-blue;
          background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5LjgyMiIgaGVpZ2h0PSI3LjA3NiIgdmlld0JveD0iMCAwIDkuODIyIDcuMDc2Ij4KICA8cGF0aCBkPSJNMTQuMTU1LDcuNzczbC0uNjM1LjYzNS0yLjc3NCwyLjc3NHEtLjg3Mi44NjktMS43NCwxLjc0YS45ODYuOTg2LDAsMCwxLTEuMzcyLDBxLS4xNzItLjE3Mi0uMzM5LS4zNDFMNC44ODksMTAuMTY5QS45Ny45NywwLDEsMSw2LjI2MSw4LjhxLjE3Mi4xNzIuMzM5LjM0MWMuNTc0LjU3NCwxLjE0OSwxLjE1MiwxLjcyLDEuNzI1TDExLjA0NCw4LjE0bDEuNzQtMS43NGEuOTcuOTcsMCwxLDEsMS4zNzEsMS4zNzJaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNC42MTIgLTYuMTIzKSIgZmlsbD0iIzI3MjcyOCIvPgo8L3N2Zz4K');
          background-position: center;
          background-repeat: no-repeat;
          background-size: 12px 10px;
        }
      }
    }
  }
}

/****************************************************************
Orange theme
****************************************************************/
.orange-theme {
  .bp__radio-checkbox__button {
    input[type='radio'],
    input[type='checkbox'] {
      &:hover {
        @extend %border-orange;
      }

      &:checked {
        @extend %border-orange;
      }
    }

    input[type='radio'] {
      &:checked {
        &::before {
          background-color: $color-orange;
        }
      }
    }

    input[type='checkbox'] {
      &:checked {
        &::before {
          background-color: $color-orange;
        }
      }
    }
  }
}
