@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/extend';

.bp__range-slider {
  display: inline-block;
  width: 100%;

  div.rs__track {
    background-color: $slider-track;
    height: 6px;
    top: 6.5px;

    &:first-of-type {
      @extend %bg-blue;

      border-radius: 5px;
      box-shadow: none;
    }
  }

  .rs{
    &__wrapper{
      height: 25px;
      position: relative;
      width: 100%;

      &.rs__with-ticks{
        height: 35px;
      }

      &.disabled{
        filter: grayscale(1);
      }

      div:nth-last-of-type(2) {
        background-color: #E9E9E9;
        border-radius: 5px;
      }
    }

    &__thumb {
      border: 3px solid $light-color1;
      border-radius: 50%;
      box-shadow: 0 0 3px 3px $slider-track-shadow;
      color: $light-color1;
      cursor: pointer;
      font-size: 10px;
      height: 25px;
      line-height: 16px;
      text-align: center;
      top: -3px;
      width: 25px;
      z-index: 3 !important;
      @extend %bg-blue;

      &:focus-visible {
        outline: 0;
      }
    }

    &__mark{
      background-color: $light-color1;
      bottom: calc(50% - 6px);
      cursor: pointer;
      height: 6px;
      margin: 0 12px;
      top: 6.5px;
      vertical-align: middle;
      width: 2px;
      z-index: 2;

      &:first-of-type{
        &::before {
          background-color: $slider-track-disabled;
          border-radius: 5px 0 0 5px;
          content: '';
          display: block;
          height: 6px;
          margin-left: -10px;
          width: 10px;
        }
      }
    }

    &__mark-value{
      color: $light-color3;
      font-size: 8px;
      left: 50%;
      line-height: 12px;
      position: absolute;
      right: 0;
      top: 20px;
      transform: translateX(-50%);
      width: max-content;

      &__dot{
        span{
          font-size: 24px;
          top: 14.5px;
        }
      }
    }

    &__text{
      font-size: 12px;
      line-height: 18px;
      margin-top: 10px;

      &--error{
        @extend %color-error;
      }
    }

    &__mark-min{
      &::before {
        background-color: $slider-track-disabled;
        content: '';
        display: block;
        height: 6px;
        margin-left: -12px;
        width: 12px;
      }
    }
  }
  &.bp__range-slider--locked{
    opacity: 0.6;
  }
}

.rs__with-2-thumb{
  .rs__track-0{
    background-color: $slider-track-disabled !important;
  }

  .rs__track-1{
    background-color: $color-blue !important;
    box-shadow: none;
  }

  .rs__track-2{
    border-radius: 0 5px 5px 0;
  }

  .rs__thumb-0{
    visibility: hidden;
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */
 
.dark-theme{
  .bp__range-slider {
    div.rs__track-0 {
      background-color: $slider-disable-track !important;
    }

    div.rs__track {
      background-color: $dark-slider-track;
    }
  
    .rs{
      &__thumb {
        border-color: $dark-bg1;
        box-shadow: 0 0 3px 3px $slider-track-shadow;
        color: $dark-color1;
      }
  
      &__mark{
        background-color: $dark-bg2;

        &:first-of-type{
          &::before {
            background-color: $slider-disable-track;
          }
        }
      }
  
      &__mark-value{
        color: $dark-color2;
      }

      &__text{
        color: $dark-color2;
      }

      &__mark-min{
        &::before {
          background-color: $slider-disable-track;
        }
      }
    }
  }
}

/****************************************************************************
Web view
****************************************************************************/

.web-view {
  .bp__range-slider {
    .rs{
      &__mark-value{
        font-size: 10px;
      }
    }
  }
}


/********************************
Orange Theme
********************************/

.orange-theme{
  .bp__range-slider{
    .rs{
      &__thumb {
        background-color: $color-orange;
      }
    }
  }
  
  .rs__with-2-thumb{
    .rs__track-1{
      background-color: $itk-orange-medium !important;
    }
  }

}