@import '../../../assets/styles/variable';

#enho-battery{
  background-color: $light-bg2;
  height: 100vh;
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme{
  #enho-battery{
    background-color: $dark-bg2;
  }
}