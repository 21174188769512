@import '../../../../assets/styles/variable';

.storageprofileassistant__ftrp__has__tutorial,
.storageprofileassistant__ftrp__has__withouttutorial {
  &__storage-pref-list-screen {
    color: #545456;
    min-height: 100%;
    padding: 15px 20px 25px;

    &__button {
      background-color: #00b6de;
      border-radius: 2em;
      color: #fff !important;
      display: flex;
      justify-content: center;
      letter-spacing: 0.03em;
      margin: 1.5em auto 1em;
      padding: 0.8em 1.4em;
      width: 80%;
    }

    .sticky-at-home {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 50px;
      margin-top: 100px;
      position: sticky;
      top: 100%;
    }

    &__ques {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    &__priority {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin: 5px 0 40px;
    }

    .storage-preference-choice-card {
      align-items: center;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 1px 2px 10px 0 rgb(63 67 71 / 8%);
      display: flex;
      margin: 15px 0;
      padding: 15px;
      position: relative;

      .bp__icon.icon-draggable {
        position: absolute;
        right: 10px;
        top: 10px;
      }

      .bp__icon.icon-up-hand-draggable {
        text-align: center;
      }

      .card-icon {
        margin-right: 15px;

        .background {
          fill: #f2fbfe;
        }
      }

      .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
      }

      .subtitle {
        color: #6b7073;
        font-size: 12px;
        font-weight: 400;
        margin-top: 5px;
      }
    }
  }
}

/*****************************************************************
Dark -theme
******************************************************************/

.dark-theme {
  .storageprofileassistant__ftrp__has__tutorial,
  .storageprofileassistant__ftrp__has__withouttutorial {
    &__storage-pref-list-screen {
      &__ques {
        color: $light-color1;
      }

      &__priority {
        color: $light-color1;
      }

      .storage-preference-choice-card {
        background-color: $dark-bg2;
        box-shadow: 0 2px 10px 0 rgb(0 0 0 / 25%);

        .card-icon {
          .background {
            fill: $dark-bg1;
          }
        }

        .title {
          color: $light-color1;
        }
      }
    }
  }
}

/* ----------------------------------------------------------------
Web View
----------------------------------------------------------------- */

@media screen and (width >= 1024px) and (height <= 1310px) {
  .as-card {
    .pc{
      &--expanded{
        >div{
          margin-bottom: 0;
        }
      }
    }

    #bp-self-consumption-profile{
      width: auto;
    }
  }
}