@import './variable';

*{
  box-sizing: border-box;
  
}

body{
  font-family: $fonts-seq;
  margin: 0; /* Safari */ /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  
  &.dark-theme { 
    background-color: #262627;
  }
}

button{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding: 12.5px;
  
  &:not(.bp__nobg-color){
    &:disabled{
      background-color: $button-bg-diabled !important;
      color: $light-color1 !important;
      pointer-events: none;
    }
  }
}

a{
  cursor: pointer;
}