@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/extend';

.edit-battery-reserve{
  color: $light-color3;
  margin-top: 16px;

  .br{
    &__title-wrapper{
      display: grid;
      gap: 16px;
      grid-auto-flow: column;
      justify-content: space-between;

      .bp__card-view--title{
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
      }
    }

    &__value{
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      @extend %color-blue;
    }
    
    &__slider-wrapper{
      display: flex;
      gap: 10px;
      margin-top: 4px;
    }

    &__slider-icon{
      margin-left: -5px;
      
      svg{
        margin-top: -3px;
      }
    }

    &__slider{
      width: 100%;
    }
  }

  .bp{
    &__range-slider{
      .rs{
        &__text{
          margin-left: -30px;
        }
      }
    }
  }

  &:not(.br__edit){
    .br{
      &__slider{
        .bp__range-slider {
          div.rs__track{
            height: 3px;
          }
  
          .rs{
            &__wrapper{
              height: 15px;
        
              &.rs__with-ticks{
                height: 30px;
              }
            }
        
            &__thumb {
              border-width: 2px;
              height: 20px;
              top: -2px;
              width: 20px;
            }
        
            &__mark{
              bottom: calc(50% - 3px);
              height: 3px;
              top: 6.5px;
        
              &:first-of-type{
                &::before {
                  height: 3px;
                }
              }
            }
        
            &__mark-value{
              top: 15px;
        
              &__dot{
                span{
                  font-size: 24px;
                  top: 14.5px;
                }
              }
            }
        
            &__text{
              font-size: 12px;
              line-height: 15px;
              margin-top: 15px;
  
              span{
                color: $light-color6;
              }
            }
        
            &__mark-min{
              &::before {
                height: 3px;
              }
            }
          }
        }
      }
    }
  }

}


/****************************************************************************
Web view
****************************************************************************/

@media screen and (width >= 1024px) and (height <= 1310px) {
  .edit-battery-reserve{
    .br{
      &__slider-wrapper{
        margin:35px 0;
      }

      &__slider-icon{
        span{
          height: 29px;
          width: 37px;
        }
        
      }
    }
  }
}


/* ----------------------------------------------------------------*
Orange Color
------------------------------------------------------------------ */

.orange-theme{
  .edit-battery-reserve{
    .br{
      &__value{
        color: $color-orange;
      }
    }
  }
}

/* ----------------------------------------------------------------*
Dark Theme
------------------------------------------------------------------ */

.dark-theme{
  .icon-battery-reserve{
    path{
      fill: $light-color1;
    }
  }

  .edit-battery-reserve{
    .br{
      &__slider{
        .rs__text{
          span{
            color: $light-color1 !important;
          }
        }
      }
    }
  }
}

/* ----------------------------------------------------------------*
Web view
------------------------------------------------------------------ */

.web-view{
  .edit-battery-reserve{
    .br{
        &__value{
        font-size: 15px;
      }
    } 
  }
}