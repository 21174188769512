@import './variable';

.loader-v2 {
  position: relative;

  // animation: rotate 2s linear infinite;
  z-index: 2;

  .path {
    animation: dash 1.5s ease-in-out infinite;
    stroke: $color-blue;
    stroke-linecap: round;
  }
  
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */

.orange-theme{
  .loader-v2 {
    .path {
      stroke: $color-orange;
    }
  }
}
