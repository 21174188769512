@import '../../../assets/styles/variable';

#enho-profile{
  background-color: $light-bg2;
  height: 100vh;
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

 .dark-theme{
  #enho-profile{
    background-color: $dark-bg2;
  }
}