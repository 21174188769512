@import '../../../../assets/styles/variable';

.storageprofileassistant {
  max-height: calc(100vh - 100px);

  .ib__btn--cancel {
    background-color: $button-bg-diabled;
    color: $light-color1 !important;
  }

  &__display__none {
    display: none;
  }

  .ftrp__tutorial {
    &__wrapper {
      text-align: center;

      &__desc {
        color: #fff;
        font-size: 12px;
        line-height: 16px;
        margin: 90px 20px 20px;
      }

      .without-encharge{
        margin: 0 0 20px;
      }
    }

    &__uphand-draggable {
      left: 0;
      position: absolute;
      right: 0;
      top: -37px;

      span {
        display: flex;
        justify-content: center;
      }
    }

    button {
      background: transparent;
      border: 1px solid;
      border-radius: 4px;
      color: #fff;
      font-size: 16px;
      outline: none;
      padding: 10px;
      text-transform: uppercase;
      width: 135px;
    }
  }

  &__ftrp__has__tutorial {
    background-color: rgb(0 0 0 / 85%);
    height: inherit;

    [data-rbd-draggable-id='1'] {
      position: relative;
      z-index: 10;
    }

    [data-rbd-draggable-id='0'],
    [data-rbd-draggable-id='2'] {
      opacity: 0.3;
      position: relative;
      z-index: 10;
    }

    &__storage-pref-list-screen {
      &__ques,
      &__priority {
        opacity: 0.3;
      }

      .sticky-at-home {
        z-index: -1;
      }
    }

  }

  &__ftrp__has__withouttutorial {
    &__storage-pref-list-screen {
      &__ques,
      &__priority {
        opacity: 1;
      }

      .sticky-at-home {
        z-index: initial;
      }
    }
  }
}

.prf-assist {
  min-height: 100%;
  padding: 25px;
  text-align: center;

  .t1 {
    color: #545456;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 25px;
  }

  .t2 {
    color: #6f6f8c;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 25px;
    margin-top: 15px;
  }

  .prf-assist_footer-actions {
    margin-top: 25px;
    position: sticky;
  }

  .edit-pref {
    color: $color-blue;
    font-size: 16px;
    font-weight: 500;
    margin-top: 25px;
  }

  .learn-how {
    align-items: center;
    border: 1px solid $color-blue;
    border-radius: 8px;
    color: $color-blue;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    height: 48px;
    justify-content: center;
    line-height: 20px;
  }

  .as-card {
    border-radius: 10px;

    &.savings__card {
      .bp__card-view {
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
        margin: 0;
        text-align: left;

        .bp__card-view__wrapper {
          background-color: #f2f2f7;
          border-radius: 10px;
          padding: 20px;
        }

        .pc__desc {
          color: $light-color3;
        }

        .reserve-battery {
          .text {
            font-size: 12px;
            font-weight: 400;
            margin-left: 10px;
          }
        }
      }

      .pc--active {
        .pc__title {
          color: $light-color2;
        }
      }

      .energy-independence-bs {
        padding: 15px 0 0;
        text-align: left;

        .ei-info-icon {
          display: none;
        }
      }
    }
  }

  .content {
    // padding: 20px;
  }

  .button {
    align-items: center;
    border: none;
    border-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    height: 45px;
    line-height: 24px;
    margin-top: 0;
    padding: 0;
    text-align: center;
    width: 100%;
  }

  .button-active {
    background-color: #01b5de;
  }

  .button-in-active {
    background-color: #a2a6a9;
    pointer-events: none;
  }

  &.already-recommended-mode {
    .t1 {
      font-size: 16px;
    }
  }
}

.bp__icon.icon-prf-assistant {
  align-items: center;
  display: flex;
  justify-content: center;

  .a {
    fill: #ebebeb;
  }

  .b {
    fill: #f0f0f0;
  }

  .c {
    fill: #f5f5f5;
  }

  .d {
    fill: #fafafa;
  }

  .e,
  .f {
    fill: #e6e6e6;
  }

  .e,
  .l {
    opacity: 0.7;
  }

  .e {
    isolation: isolate;
    mix-blend-mode: multiply;
  }

  .g,
  .j {
    fill: #fff;
  }

  .h {
    fill: #407bff;
  }

  .i {
    fill: #01b5de;
  }

  .j {
    opacity: 0.8;
  }

  .k {
    fill: #54d3e8;
    opacity: 0.3;
  }

  .l {
    fill: #9deefc;
  }

  .m {
    fill: #b55b52;
  }

  .n {
    fill: #263238;
  }

  .o {
    fill: #a02724;
  }

  .p {
    opacity: 0.2;
  }

  .q {
    fill: #afc7f2;
  }
}

.showup {
  &.showup__action__inline {
    .showup__body {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      padding: 20px 10px;
    }

    .showup__footer {
      padding: 0;
    }

    .showup__actions {
      align-items: center;
      border-top: 1px solid #ecedee;
      display: flex;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
    }

    .showup__action__btn {
      span {
        background-color: transparent;
        color: #01b4de;
        padding: 0;
      }
    }

    .showup__action {
      font-size: 16px;
      font-weight: 400;
      padding: 15px;
      width: 50%;

      &:not(:first-child) {
        border-left: 1px solid #ecedee;
        margin: 0;
      }
    }
  }
}

/****************************************************************
Dark - theme
*****************************************************************/

.dark-theme {
  .prf-assist {
    .t1,
    .t2 {
      color: $light-bg1;
    }

    .as-card {
      background-color: $dark-bg1;

      &.savings__card {
        .bp__system-profile--card {
          background-color: $dark-bg2;
        }

        .bp__card-view {
          .bp__card-view__wrapper {
            background-color: $dark-bg1;
          }

          .pc__desc {
            color: $dark-bg3;
          }

          .pc__title {
            color: $light-color1
          }
        }
      }
    }

    .withtitledescr {
      .prf-assistwithtitledescr__title {
        color: $light-bg1;
      }

      .prf-assistwithtitledescr__descr {
        color: $dark-bg3;
      }
    }

    .reserve-battery {
      .text {
        color: $light-bg3;
      }
    }

  }
}


/****************************************************************
Web View
****************************************************************/

.web-view {
  .sph__find-right-profile-popup {
    .t1 {
      font-size: 20px;
    }

    .e_dialog {
      &__wrapper {
        width: 650px;
      }
    }

    .as-card {
      .sp__profile {
        padding: 0;

        .sp__profile--wrapper {
          .com__ad--title {
            font-size: 16px !important;
          }
        }

        .sp__desc {
          font-size: 12px;
          margin-top: 9px
        }

        .bp__card--options {
          .edit-after-peak-hours {
            .com__ad__title {
              font-size: 12px;
            }

            .ts__status-text {
              font-size: 12px;
            }
          }

          .com__ad--title {
            font-size: 12px;
          }
        }
      }
    }
  }
}