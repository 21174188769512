@import '../../../../assets/styles/variable';

.sph__st__popup__wrapper {
  color: $light-color3;
  font-size: 12px;
  margin: auto;
  padding: 10px 0 25px;
  width: 90%;

  &__link {
    align-items: center;
    color: $color-blue;
    display: flex;
    width: fit-content;

    .bp__icon {
      margin-left: 5px;
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

 .dark-theme{
  .sph__st__popup__wrapper {
    color: $dark-color1;
  }
}

/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */

 .orange-theme {
  .sph__st__popup__wrapper {
    &__link {
      color: $color-orange;
    }
  }
}