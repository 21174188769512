@import './variable';
@import './mixin';

/*
  --- --- --- --- --- ---
  How to use Extend
  --- --- --- --- --- ---
  .selector{
    @extend %hr-vr-center;
  }
*/

%hr-vr-center {
  left: 50%;
  top: 50%;

  @include transform(translate(-50%, -50%));
}

%vr-center {
  top: 50%;

  @include transform(translateY(-50%));
}

%hr-center {
  left: 50%;

  @include transform(translateX(-50%));
}

/* Color */
%color-orange {
  color: $color-orange;
}

%color-light{
  color: $light-bg1
}

%color-blue {
  color: $color-blue;
}

%color-error {
  color: $color-error;
}

%card-color-active {
  color: #35c37d;
}

/* Background */
%bg-blue {
  background-color: $color-blue;
}

%bg-orange {
  background-color: $color-error;
}

/* Fill */
%fill-blue {
  fill: $color-blue;
}

/* Border Color */
%border-blue {
  border-color: $color-blue;
}

%border-orange {
  border-color: $color-orange;
}