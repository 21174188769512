@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/extend';

#bp-use-battery-for-ev {
  color: $light-color2;

  .cbfev {
    &__title-toggle {
      display: grid;
      gap: 16px;
      grid-auto-flow: column;
      justify-content: space-between;
    }

    &__status-info {
      align-items: center;
      display: grid;
      grid-auto-flow: column;
      height: 20px;
      width: fit-content;
    }

    &__status-icon {
      margin-right: 5px;

      svg {
        height: 15px;
        width: 15px;
      }

      .icon-clock-circular {
        path {
          @extend %fill-blue;
        }
      }
    }

    &__status-text {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      margin-right: 5px;
    }

    &__info {
      margin-top: -2px;
    }
  }

}

.cbfev {
  &__popup__wrapper {
    padding: 20px 20px 25px;
  }

  .e_dialog__content {
    font-weight: 400;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__info-popup {
    .bp__status-text--disabled {
      color: $light-color2;
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme {
  #bp-use-battery-for-ev {
    color: $dark-color2;
    }
  
  .cbfev {
    &__info-popup {
      .bp__status-text--disabled {
        color: $dark-color1;
      }
    }
  }
}


/****************************************************************************
Web view
****************************************************************************/

.web-view {
  #bp-use-battery-for-ev {
    .cbfev {
      &__title-toggle {
        .cbfev__toggle {
          .cbfev__status__toggle-container {
            display: flex;

            .cbfev__status-text {
              margin-right: 50px;
              margin-top: 4px;
            }
          }
        }

        .bp__card-view--title {
          align-items: center;
          display: flex;

          span {
            .bp__icon {
              svg {
                margin-left: 10px;
              }

            }
          }
        }
      }
    }

    .bp__card-view--title{
      width: 100%;
    }
  }
}