@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/extend';

#bp-battery-shutdown{
  color: $light-color2;

  .bs{
    &__title-wrapper{
      display: grid;
      gap: 16px;
      grid-auto-flow: column;
      justify-content: space-between;
    }

    &__value{
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      @extend %color-blue;
    }

    &__slider-wrapper{
      display: flex;
      gap: 10px;
      margin-top: 20px;
    }

    &__slider-icon{
      margin-left: -5px;
      
      svg{
        margin-top: -3px;
      }
    }

    &__slider{
      width: 100%;

      .bp__range-slider {
        div.rs__track{
          height: 3px;
        }

        .rs{
          &__wrapper{
            height: 15px;
      
            &.rs__with-ticks{
              height: 20px;
            }
          }
      
          &__thumb {
            border-width: 2px;
            height: 20px;
            top: -2px;
            width: 20px;
          }
      
          &__mark{
            bottom: calc(50% - 3px);
            height: 3px;
            top: 6.5px;
      
            &:first-of-type{
              &::before {
                height: 3px;
              }
            }
          }
      
          &__mark-value{
            font-size: 10px;
            top: 15px;
      
            &__dot{
              span{
                font-size: 24px;
                top: 14.5px;
              }
            }
          }
      
          &__text{
            font-size: 12px;
            line-height: 15px;
            margin-top: 15px;

            &--error{
              color: $color-red-error !important;
            }

            span{
              color: $light-color6;
            }
          }
      
          &__mark-min{
            &::before {
              height: 3px;
            }
          }
        }
    }
  }
  }

  .bp{
    &__range-slider{
      .rs{
        &__text{
          margin-left: -30px;
        }
      }
    }
  }
}


/****************************************************************************
Web view
****************************************************************************/

@media screen and (width >= 1024px) and (height <= 1310px) {
  #bp-battery-shutdown{
    .bs{
      &__slider-wrapper{
        margin:35px 0;
      }

      &__slider-icon{
        span{
          height: 29px;
          width: 37px;
        }
      }
    }
  }
}


/* ----------------------------------------------------------------*
Orange Color
------------------------------------------------------------------ */

.orange-theme{
  #bp-battery-shutdown{
    .bs{
      &__value{
        color: $color-orange;
      }
    }
  }
}