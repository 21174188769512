@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/extend';

#bp-storm-guard-details {
  padding: 0;

  &.bp-storm-guard__has-page {
    .bp__storm-guard {
      .sg {
        &__wrapper {
          align-items: center;
          display: grid;
          gap: 16px;
          grid-auto-flow: column;
          justify-content: space-between;
          padding: 18px 20px;
        }
      }
    }
  }

  .bp__storm-guard {
    border-radius: 0;
    cursor: pointer;
  }
}

/* *********************** *********************** *********************** *
 * Web View
 * *********************** *********************** *********************** */

.web-view {
  #bp-page-storm-guard {
    display: flex;
    justify-content: center;
    margin: auto;
    overflow: auto;

    .bp__page--wrapper {
      width: 1050px;
    }
  }

  #bp-storm-guard-details {
    padding: 32px 0 0;

    .bp__storm-guard {
      border-radius: 8px;

      .sg__wrapper {
        padding: 32px;
      }
    }
  }
}
