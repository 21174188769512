@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/extend';

#bp-discharge-battery-to-grid {
  color: $light-color2;

  .dbtg {
    &__title-toggle {
      display: grid;
      gap: 16px;
      grid-auto-flow: column;
      justify-content: space-between;
    }

    &__status-info {
      align-items: center;
      display: grid;
      grid-auto-flow: column;
      height: 20px;
      width: fit-content;
    }

    &__status-text {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      margin-right: 5px;

      &.dbtg__profile--savings {
        .bp__status-text--enabled {
          color: #f9b98f;
        }
      }
    }

    &__info {
      margin-top: -2px;
    }

    &__grid-tied,
    &__grid-agnostic {
      font-size: 12px;
      line-height: 16px;
      margin-top: 12px;
    }

    &__grid-tied {
      input[type='checkbox'] {
        margin: 3px 10px 0 0;
      }
    }

    &__time-selection {
      align-items: center;
      background-color: #f5f5f8;
      border-radius: 8px;
      display: flex;
      margin-top: 16px;
      padding: 16px;

      .bp__icon {
        margin-bottom: 2px;
        margin-right: 10px;
      }

      &__label {
        color: $light-color2;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin-right: 5px;
      }

      &__value {
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        @extend %color-blue;
      }

      .icon-time-selection {
        height: 16px;
        width: 16px;
      }

      &--start {
        display: flex;
        margin-right: 24px;
      }

      &--end {
        display: flex;
      }

      &--disabled {
        opacity: 0.5;
        pointer-events: none;

        .dbtg {
          &__time-selection {
            &__value {
              color: #01b5de;
              cursor: auto;
            }
          }
        }
      }

      &__message {
        color: $light-color2;
        font-family: $fonts-seq;
        font-size: 12px;
        font-weight: 400;

        .time-label {
          font-weight: 500;
        }
      }
    }

    &__time-error {
      color: #e3655e;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin-top: 16px;
    }

    &__nem3-tariff__info {
      display: flex;
      justify-content: start;
      padding: 8px 0 0 1px;

      .info-description {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        padding: 1px 0 0 8px;

        .desc {
          color: $light-color8;
        }

        .desc-link {
          color: $color-blue;
          cursor: pointer;
          font-weight: 500;
        }
      }
    }
  }

  .lock-dtg-toggle {
    .react-switch-bg {
      background: $light-bg4 !important;
    }

    .react-switch-handle {
      background: $light-color9 !important;
    }

    &.bp_ts_on.bp_ts_disabled {
      .bp_ts_handler {
        opacity: 1 !important;
      }
    }
  }
}

.dbtg {
  &__popup__wrapper {
    padding: 20px 20px 25px;

    .bp {
      &__action-button {
        &:last-child {
          background-color: transparent !important;
          color: #00b6de;
          font-weight: 500;
        }

        &.bp__ab--disabled {
          color: $disabled !important;
        }
      }
    }

    .e_dialog__content {
      &.bp__radio-checkbox__button {
        display: flex;
        font-weight: 500;

        input[type='checkbox'] {
          margin: 3px 10px 0 0;
        }

        .bp__radio-checkbox__description {
          align-items: end;
          display: flex;
          width: 90%;

          label {
            padding-bottom: 2px;
          }
        }
      }
    }
  }

  .e_dialog__content {
    font-weight: 400;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &__info-popup {
    .bp__status-text--disabled {
      color: $light-color2;
    }
  }
}

.nem3 {
  &_alert-popup {
    &__wrapper {
      align-items: start;
      display: flex;
      flex-direction: column;

      &-header-title {
        color: $light-color2;
        font-family: $fonts-seq;
        font-size: 16px;
        font-weight: 500;
        padding: 16px;
      }

      &-content {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 24px 16px 24px 16px;
        width: 100%;

        &-description {
          color: $light-color2;
          font-family: $fonts-seq;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          margin-top: 24px;
          text-align: center;
        }
      }

      &-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0 16px 16px 16px;
        width: 100%;

        .primary-btn {
          background: $color-blue;
          border: 1px solid $color-blue;
          border-radius: 8px;
          color: #ffff;
          cursor: pointer;
          font-family: $fonts-seq;
          font-size: 16px;
          font-weight: 500;
          margin-left: 8px;
          padding: 12px 16px 12px 16px;
          width: 100%;
        }

        .secondary-btn {
          background: transparent;
          border: 1px solid $color-blue;
          border-radius: 8px;
          color: $color-blue;
          cursor: pointer;
          font-family: $fonts-seq;
          font-size: 16px;
          font-weight: 500;
          margin-right: 8px;
          padding: 12px 16px 12px 16px;
          width: 100%;
        }
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme {
  #bp-discharge-battery-to-grid {
    color: $dark-color2;

    .dbtg {
      &__time-selection {
        background-color: #262627;

        &__label {
          color: $dark-color1;
        }

        &__message {
          color: $dark-color1;
        }

        .icon-time-selection {
          svg circle,
          svg path {
            stroke: #fff;
          }
        }
      }

      &__nem3-tariff__info {
        .icon {
          .icon-info-v3 {
            svg path {
              fill: #6b7073;
            }
          }
        }

        .info-description {
          .desc {
            color: #6b7073;
          }
        }
      }
    }

    .lock-dtg-toggle {
      .react-switch-bg {
        background: $dark-color2 !important;
      }

      .react-switch-handle {
        background: $dark-color4 !important;
      }
    }
  }

  .dbtg {
    &__info-popup {
      .bp__status-text--disabled {
        color: $dark-color1;
      }
    }

    &__restrict-popup {
      .e_dialog__wrapper {
        .e_dialog__body {
          .cbfg__popup__wrapper {
            padding: 30px 20px;

            .icon-info-alert {
              display: flex;
              justify-content: center;

              svg {
                path {
                  fill: #fff;
                }

                path:not(:first-child) {
                  fill: #fff;
                  stroke: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .nem3 {
    &_alert-popup {
      &__wrapper {
        &-header-title {
          color: $light-color1;
        }

        &-content {
          .icon-error {
            svg path:nth-child(2),
            path:nth-child(3) {
              stroke: $dark-bg2;
            }
          }

          &-description {
            color: $light-color1;
          }
        }
      }
    }
  }
}

/****************************************************************************
Web view
****************************************************************************/

.web-view {
  #bp-discharge-battery-to-grid {
    .dbtg {
      &__title-toggle {
        .dbtg__toggle {
          .dbtg__status__toggle-container {
            display: flex;

            .dbtg__status-text {
              margin-right: 12px;
              margin-top: 1px;
            }
          }
        }

        .bp__card-view--title {
          align-items: center;
          display: flex;

          span {
            .bp__icon {
              svg {
                margin-left: 10px;
              }
            }
          }
        }
      }

      &__time-selection {
        &__message {
          font-size: 16px;
        }

        .icon-time-selection {
          height: 24px;
          width: 24px;
        }
      }

      &__nem3-tariff__info {
        margin-top: 8px;

        .info-description {
          font-size: 16px;

          .desc-link {
            cursor: pointer;
            font-weight: 500;
          }
        }
      }
    }
  }

  .dbtg {
    &__itc-popup {
      .bp {
        &__action-button {
          &:last-child {
            background-color: #00b6de !important;
          }

          &.bp__ab--disabled {
            color: $disabled !important;
          }
        }
      }
    }

    &__popup__wrapper {
      .bp {
        &__action-button {
          &:last-child {
            background-color: #00b6de !important;
          }

          &.bp__ab--disabled {
            color: $disabled !important;
          }
        }
      }
    }

    &__restrict-popup {
      .e_dialog__wrapper {
        .e_dialog__footer {
          padding: 0 20px 20px;
        }
      }
    }
  }

  .nem3_alert-dialog {
    .e_dialog__wrapper {
      max-width: 672px;
      width: auto;

      .nem3 {
        &_alert-popup {
          &__wrapper {
            &-header-title {
              border-bottom: 1px solid $light-border;
              font-size: 20px;
              padding: 24px;
              width: 100%;
            }

            &-content {
              padding: 32px 24px 32px 24px;

              &-description {
                font-size: 16px;
                margin-top: 32px;
              }
            }

            &-button {
              padding: 16px;

              button {
                font-size: 20px;
                padding: 16px 24px 16px 24px;
              }
            }
          }
        }
      }
    }
  }
}

/* ----------------------------------------------------------------
Orange Theme
---------------------------------------------------------------- */

.orange-theme {
  #bp-discharge-battery-to-grid {
    .dbtg {
      &__time-selection {
        &__value {
          color: $color-orange !important;
        }
      }
    }
  }
}
