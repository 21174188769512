@import '../../../../assets/styles/variable';

.bp-battery-mode {
  padding: 0 !important;

  &-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  &-container {
    background-color: #fff;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
    padding: 15px 20px;

    &-flex {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &-title {
      color: $light-color2;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin: 0;

      .value-margin-left {
        font-weight: 400;
        margin-left: 6px;
      }
    }

    &-description {
      color: $light-color6;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      margin-top: 4px;

      p {
        margin: 0;
      }
    }

    .arrow {
      border-color: $button-bg-diabled;
      border-style: solid;
      border-width: 0.15em 0.15em 0 0;
      content: '';
      display: inline-block;
      height: 0.45em;
      left: 0.15em;
      position: relative;
      top: 0;
      vertical-align: top;
      width: 0.45em;
    }

    .arrow-down {
      transform: rotate(135deg);
    }

    .arrow-up {
      transform: rotate(315deg);
    }
  }

  &-description {
    margin-top: 16px;

    &-container {
      background-color: $light-bg2;
      border-radius: 6px;
      padding: 12px;

      .title {
        color: $light-color2;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        margin: 0;
        margin-bottom: 8px;
      }

      .sub-title {
        align-items: center;
        color: $light-color6;
        display: flex;
        font-size: 12px;
        font-weight: 400;
        justify-content: flex-start;
        line-height: 16px;
        margin: 0;
        margin-bottom: 4px;

        .margin-left {
          margin-left: 4px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &-information {
    margin-top: 8px;

    p {
      color: $dark-color2;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin: 0;
    }
  }

  .bp__height-auto {
    height: auto;
    transition: all 5s ease-in-out;
  }

  .height-none {
    height: 0;
  }
}

.dark-theme {
  .bp-battery-mode {
    &-container {
      background-color: $dark-bg1;
      box-shadow: 0 2px 10px 0 rgb(0 0 0 / 30%);

      &-title {
        color: $light-bg1;
      }

      &-description {
        color: $dark-color2;
      }

      .arrow {
        border-color: #cbcfd2;
      }
    }

    &-description {
      &-container {
        background-color: $dark-bg2;

        .title {
          color: $dark-color1;
        }

        .sub-title {
          color: $dark-color2;
        }
      }
    }
  }
}

.mobile-view {
  .bp-battery-mode {
    &-container {
      margin: -20px -20px 0;
    }
  }
}

.web-view {
  .bp-battery-mode {
    &-container {
      padding: 24px;

      &-title {
        font-size: 20px;
        line-height: 28px;
      }

      &-description {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &-description {
      margin-top: 24px;

      &-container {
        padding: 16px;

        .title {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 16px;
        }

        .sub-title {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 8px;

          svg {
            height: 20px;
            width: 20px;
          }

          .margin-left {
            margin-left: 8px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    &-information {
      margin-top: 12px;

      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
