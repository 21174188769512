@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/extend';

#bp-select-profile-help{
  background-color: #01b5de0f;
  box-shadow: none;
  color: $light-color2;
  cursor: pointer;
  margin: 0 0 16px;

  .bp__card-view__wrapper{
    padding: 12px 14px;
  }

  .sph{
    &__title-wrapper{
      display: grid;
      gap: 16px;
      grid-auto-flow: column;
      justify-content: space-between;
    }

    &__title-icon{
      align-items: center;
      display: grid;
      grid-auto-flow: column;
      justify-content: flex-start;
      margin-left: -2px;
    }

    &__title{
      font-size: 12px;
      font-weight: 500;
      line-height: 21px;

      @extend %color-blue;
    }

    &__icon{
      margin-right: 12px;
    }
  }
}

.sph{
  &__select-tutorial-popup {
    .e_dialog__body {
      background-color: $light-color1;
      padding: 15px 20px 30px;
    }

    .crp__selection {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .crp__selection__item {
        background-color: rgb(1 181 222 / 4.3%);
        border-radius: 10px;
        color: rgb(1 182 222);
        cursor: pointer;
        font-size: 16px;
        line-height: 18px;
        padding: 20px;
        width: 100%;
       
        .bp__icon{
          svg{
            margin-bottom: 20px;
          }
        }
      }

      .crp__selection__item:nth-child(1){
        margin-right: 16px;
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Web View
 * *********************** *********************** *********************** */

.web-view{
  #bp-select-profile-help{
    margin: 0 0 32px;

    .bp__card-view__wrapper{
      padding: 32px !important;
    }
  }

  .sph{
    &__select-tutorial-popup {
      .e_dialog__body{
        padding: 32px;
      }

      .crp__selection {  
        .crp__selection__item:nth-child(1){
          margin: 0 0 24px;
        }
      }
    }
  }
}

 @media screen and (width >= 1024px) and (height <= 1310px) {
  #bp-select-profile-help{
    .sph{
      &__title{
        font-size: 14px;
      }
    }
  }

  .sph{
    &__select-tutorial-popup {
      .crp__selection {
       flex-direction: column;
       margin: auto;
       width: 60%;
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme{
  #bp-select-profile-help{
    border: 1px solid $color-blue;
  }
}

/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */

.orange-theme{
  #bp-select-profile-help{
    background-color: $itk-orange-light;

    .sph{
      &__title-icon{
        .sph__icon{
          .icon-tutorial{
            path{
              fill: $color-orange;
            }
          }
        }
      }

      &__title{
        color: $color-orange
      }
    }
  }
}
