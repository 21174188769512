/*
  --- --- --- --- --- ---
  How to use Mixin
  --- --- --- --- --- ---
  .selector{
    @include transform(rotate(30deg));
  }
*/

@mixin transform($property) {
  transform: $property;
}

@mixin placeholder {
  &::placeholder {
    @content;
  }
}

@mixin user-select {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
}

@mixin text-ellipse {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin text-ellipse-multiline($line) {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  overflow: hidden;
}
