@import '../../../../assets/styles/variable';

#bp-page-profile-details {
  .pd {
    &__top--title {
      border-bottom: 1px solid $light-border;
      color: $light-color2;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      margin-bottom: 16px;
      padding-bottom: 12px;
    }
  }

  .dr-events {
    &__wrapper {
      .bp__link {
        color: $color-blue;
      }
    }

    &__content {
      margin-top: 16px;
    }
  }
}

#bp-page-edit-profile {
  .pd__profile-container {
    height: 100%;
    padding-bottom: 0;

    .bp__page--actions{
      padding-bottom: 20px;

      .bp__ab--disabled {
        &:disabled {
          background-color: #BABEC3 !important;
          border-color: #BABEC3 !important;
          color: #6B7073 !important;
        }
      }

    }
  }
}

.cp {
  &_alert-popup {
    &__wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 40px 20px 25px;

      &-content {
        color: $light-color2;
        font-family: $fonts-seq;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        margin-top: 25px;
        text-align: center;
      }
    }
  }
}


.sp{
  &__tou-alert-popup{
    .e_dialog__body__wrapper{
      padding-top: 40px;
    }

    .bp__icon{
      margin-top: 20px;

      &.icon-alert{
        height: 50px;
        width: 50px;

        svg{
          margin: auto;
        }
      }

      &.icon-info-v2{
        height: 50px;
        width: 50px;

        svg{
          margin: auto;
        }
      }
    }

    .e_dialog__content{
      font-size: 14px;
      line-height: 18px;
      margin: 16px 0 0;
      text-align: center;

      &:first-of-type{
        margin-top: 25px;
      }
    }
  }
}


/* *********************** *********************** *********************** *
 * Web View
 * *********************** *********************** *********************** */

 .web-view{
  #bp-page-profile-details, #bp-page-edit-profile {
    display: flex;
    justify-content: center;
    margin: auto;
    overflow: auto;

    .bp__page--wrapper {
      width: 1050px;
    } 
  }

  #bp-page-profile-details {
    .pd {
      &__top--title {
        font-size: 15px;
        margin-bottom: 16px;
      }

      &__wrapper {
        .pd__profile-container {
          display: grid;
          gap: 32px;
          grid-template-columns: 1fr 1fr 1fr;
          height: 380px;
        }
      }

      &__title {
        margin-bottom: 16px;
        margin-top: 24px;
      }
    }
  }

  #bp-page-edit-profile{
    .bp__page--actions{
      justify-content: center;
      margin-top: 40px;

      .bp__action-button {
        border-radius: 25px;
        width: 20%;
      }
    }
  }
  
  .e_dialog.e_dialog__profile.dt-tou-alert {
    .e_dialog__footer__wrapper {
      flex-direction: column;
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme {
  #bp-page-profile-details {
    .pd {
      &__top--title {
        border-color: $light-color6;
        color: $dark-color1;
      }
    }
  }

  #bp-page-edit-profile {
    .pd__profile-container {
      .bp__page--actions{
        .bp__ab--disabled {
          &:disabled {
            background-color: #6B7073 !important;
            border-color: #6B7073 !important;
            color: #fff !important;
          }
        }
      }
    }
  }

  .cp_alert-popup {
    .e_dialog {
      &__body {
        background-color: $dark-bg2;

        .cp {
          &_alert-popup {
            &__wrapper {
              &-content {
                color: #fff;
              }
            }
          }
        }
      }
    }

    .e_dialog__footer__wrapper {
      background-color: $dark-bg2;
      border-color: $dark-border;
    }
  }
}

/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */

.orange-theme {
  .cp {
    &_alert-popup {
      .bp__action-button {
        color: $color-orange;
      }
    }
  }
}
