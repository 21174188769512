@import '../../../assets/styles/variable';
@import '../../../assets/styles/extend';

#homepage{
  align-items: center;
  display: grid;
  font-family: $fonts-seq;
  height: 100vh;
  justify-content: center;

  >div{
    color: $light-color1;
    font-size: 2.5rem;
    padding: 20px;
    @extend %bg-orange;

    >div{
      border-top: 1px solid $light-color1;
      font-size: 1.5rem;
      padding-top: 10px;
      text-align: center;
    }
  }
}


/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

 .dark-theme{
  #homepage{
    background-color: $dark-bg2;
  }
}