@import '../../../../assets/styles/variable';

.live-status__energy-flow {
  &__wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;

    &__inner-wrapper {
      margin: auto;
      margin-top: 20px;
      padding: 0;
    }
  }
}

.live-status__energy-flow__wrapper__inner-wrapper__top {
  align-items: center;
  display: flex;
  justify-content: center;

  .energy-type {
    &__icon-with-details {
      margin-bottom: 5px;

      &__details {
        &__value {
          color: $color-blue;
        }
      }
    }
  }
}

.live-status__energy-flow__wrapper__inner-wrapper__center {
  align-items: center;
  display: flex;
  justify-content: center;
  min-width: 270px;
  position: relative;

  &__system-grid-status {
    bottom: -26px;
    height: 100%;
    left: 0;
    position: absolute;

    .energy-type {
      &__icon-with-details {
        &__details {
          &__value {
            color: #545456;
          }
        }
      }
    }

    &.disconnected {
      .energy-type__icon {
        opacity: 0.5;
      }
    }
  }

  &__generator {
    .energy-type {
      margin-top: 10px;
    }
  }

  // arrows

  &__arrows {
    height: 95px;
    position: relative;
    width: 116px;

    .icon {
      height: 100%;
    }

    &__arrows-top,
    &__arrows-bottom {
      display: flex;
      height: 50%;
      justify-content: space-between;
      width: 100%;

      > div {
        width: 50%;
      }

      .icon {
        svg {
          height: 60px;
          width: 60px;
        }
      }
    }

    &__arrows-top {
      .icon-savings-mode-off-peak-with-solar,
      .icon-self-consumption,
      .icon-full-backup,
      .icon-imported {
        left: 63px;
        position: absolute;
        top: -8.5px;

        svg{
          height: 60px;
          width: 60px;
        }
      }

      .icon-savings-mode-peak, .icon-savings-mode-off-peak-without-solar {
        left: -7px;
        position: absolute;
        top: -8.5px;

        svg{
          height: 60px;
          width: 60px;
        }
      }
    }

    &__arrows-horizontal {
      align-items: center;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      position: absolute;
    }

    &__arrows-vertical {
      align-items: center;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      position: absolute;

      .icon-savings-mode-off-peak-with-solar,
      .icon-self-consumption,
      .icon-full-backup,
      .icon-imported {
        left: 51px;
        position: absolute;
        top: 6px;
      }
    }

    &__arrows-bottom {
      .icon-savings-mode-off-peak-with-solar,
      .icon-self-consumption,
      .icon-full-backup,
      .icon-imported {
        left: 63px;
        position: absolute;
        top: 44px;

        svg{
          height: 60px;
          width: 60px;
        }
      }

      .icon-savings-mode-off-peak-without-solar{
        position: absolute;
        right: -6px;
        top: 39px;

        svg{
          height: 60px;
          width: 60px;
        }
      }

      .icon-savings-mode-peak{
        position: absolute;
        right: -6px;
        top: 39px;

        svg{
          height: 60px;
          width: 60px;
        }
      }

      .icon-savings-mode-grid-to-storage {
        left: -8px;
        position: absolute;
        top: 54px;
      }

      .pointer-savings-mode-grid-to-storage {
        left: -8px;
        position: absolute;
        top: 44px;

        svg{
          height: 60px;
          width: 60px;
        }
      }
    }
  }

  &__consumed {
    bottom: -26px;
    height: 100%;
    position: absolute;
    right: 0;

    .energy-type {
      &__icon-with-details {
        &__details {
          &__value {
            color: $color-error;
          }
        }
      }
    }
  }
}

.live-status__energy-flow__wrapper__inner-wrapper__bottom {
  align-items: center;
  display: flex;
  justify-content: center;

  .energy-type {
    &__icon-with-details {
      &__details {
        &__value {
          color: #7acf38;
        }
      }
    }
  }
}

.bp__profile__wrapper__live-status.arrows-without-battery {
  margin: 40px 0 70px;

  .live-status__energy-flow__wrapper__inner-wrapper__center__arrows__arrows-top {
    div {
      &:nth-child(1) {
        span {
          left: -7px;
          position: absolute;
          top: -8.5px;

          svg{
            height: 60px;
            width: 60px;
          }
        }
      }

      &:nth-child(2) {
        span {
          left: 63px;
          position: absolute;
          top: -8.5px;

          svg{
            height: 60px;
            width: 60px;
          }
        }
      }
    }
  }
}

/* Overriding energy-type styles */

/********************************
  Dark Theme
  *********************************/
.dark-theme {
  .live-status__energy-flow__wrapper__inner-wrapper__top {
    .energy-type {
      &__icon-with-details {
        &__icon {
          span {
            svg {
              g {
                fill: none;
              }
            }
          }
        }
      }
    }
  }

  .live-status__energy-flow__wrapper__inner-wrapper__center {
    &__system-grid-status {
      .energy-type {
        &__icon-with-details {
          &__icon {
            .icon-imported {
              svg g path:not(:first-child) {
                stroke: #cbcde1;
              }
            }
          }

          &__details {
            &__value {
              color: #cbcde1;
            }
          }
        }
      }
    }

    &__arrows {
      .icon-energy-grid-to-home-connected-lmab {
        g {
          #line {
            path {
              fill: #cbcde1;
              stroke: #cbcde1;
            }
          }

          #pointer-grid-home-savings-mode-off-peak-with-solar,
          #pointer-grid-home-self-consumption,
          #pointer-grid-home-full-backup {
            circle {
              fill: #cbcde1;
            }
          }
        }
      }

      &__arrows-bottom {
        .icon-savings-mode-grid-to-storage {
          #pointer-grid-battery-savings-mode-grid-to-storage {
            ellipse {
              fill: #cbcde1;
            }
          }
          g {
            path:nth-child(2) {
              stroke: #cbcde1;
            }
            path:nth-child(3) {
              fill: #cbcde1;
            }
          }
        }
      }

      &__arrows-horizontal {
        .icon-savings-mode-off-peak-with-solar,
        .icon-self-consumption,
        .icon-full-backup,
        .icon-savings-mode-peak-grid-to-home,
        .icon-savings-mode-grid-to-storage,
        .icon-imported {
          #pointer-grid-home-savings-mode-peak-grid-to-home,
          #pointer-grid-home-savings-mode-off-peak-with-solar,
          #pointer-grid-home-self-consumption,
          #pointer-grid-home-full-backup,
          #pointer-grid-home-savings-mode-grid-to-storage {
            circle {
              fill: #cbcde1;
            }
          }

          g {
            path:nth-child(1) {
              stroke: #cbcde1;
            }

            path:nth-child(2) {
              fill: #cbcde1;
            }
          }
        }
      }
    }

    .energy-type {
      &__icon-with-details {
        &__icon {
          span {
            g {
              fill: none;
            }
          }
        }
      }
    }
  }

  .live-status__energy-flow__wrapper__inner-wrapper__bottom {
    .energy-type {
      &__icon-with-details {
        &__icon {
          span {
            g {
              fill: none;
            }
          }
        }
      }
    }
  }
}
