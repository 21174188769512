@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/extend';

.bp__storm-guard {
  background-color: $light-color1;
  border-radius: 8px;
  box-shadow: 1px 2px 10px 0 rgb(63 67 71 / 8%);
  color: $light-color2;

  .sg {
    &__wrapper {
      padding: 20px;
    }

    &__title-toggle {
      display: grid;
      gap: 16px;
      grid-auto-flow: column;
      justify-content: space-between;
    }

    &__title {
      align-items: center;
      display: grid;
      font-size: 16px;
      font-weight: 500;
      grid-auto-flow: column;
      line-height: 24px;
    }

    &__info {
      cursor: pointer;
      margin-left: 5px;
      margin-top: -2px;
    }
  }

  .bp__card-view--desc {
    margin-top: 4px;
  }

  .sg__ev {
    &__section {
      background-color: $light-bg2;
      border-radius: 8px;
      margin-top: 15px;
      padding: 12px 14px;
    }

    &__title {
      align-items: center;
      display: flex;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    &__wrapper {
      display: inline-grid;
      grid-auto-flow: column;
      grid-gap: 5px;
      margin-top: 10px;
    }

    &__warning {
      align-items: center;
      display: flex;

      .icon-warning {
        margin-top: 4px;
      }

      .warning__text {
        margin-left: 5px;
      }
    }
  }
}

.sg {
  &__popup__wrapper {
    padding: 20px 20px 35px;

    .bp_reference_text {
      color: #7b8793b2;
    }
  }

  &__info-popup {
    [role='button'].bp__link {
      cursor: pointer;
      display: flex;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin-top: 16px;

      .bp__icon {
        margin-left: 5px;
      }
    }
  }

  &__change-popup {
    .e_dialog__wrapper {
      max-width: 450px;
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme {
  .bp__storm-guard {
    background-color: $dark-bg1;
  }

  .sg {
    &__popup__wrapper {
      .bp_reference_text {
        color: #cbced1;
      }
    }

    &__ev__section {
      background-color: $dark-bg2;

      .bp__radio-checkbox__button {
        input[type='checkbox'] {
          &:disabled {
            background-color: $dark-bg2;
          }
        }
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Web View
 * *********************** *********************** *********************** */

.web-view {
  .bp__storm-guard {
    .bp__card-view--desc {
      font-size: 15px;
      line-height: 20px;
      margin-top: 8px;
    }

    .sg {
      &__wrapper {
        padding: 32px;
      }

      &__title {
        font-size: 20px;
        line-height: 28px;
      }

      &__toggle {
        align-self: center;
        display: grid;
        gap: 32px;
        grid-auto-flow: column;
      }

      &__ev {
        &__wrapper {
          align-items: center;
        }

        &__checkbox {
          margin-top: 0;
        }
      }
    }

    &.bp__sg__status--disabled {
      border-radius: 8px;
    }
  }

  .sg {
    &__info-popup {
      [role='button'].bp__link {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Media query
 * *********************** *********************** *********************** */

@media screen and (width < 375px) {
  .bp__storm-guard {
    .sg {
      &__title-toggle {
        gap: 3px;
      }
    }
  }
}
