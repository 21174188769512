@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/extend';

#comp-show-error-details{
  align-items: center;
  display: grid;
  font-family: $fonts-seq;
  height: 100vh;
  justify-content: center;

  >div{
    color: $light-color1;
    font-size: 2.5rem;
    padding: 20px;
    @extend %bg-orange;

    >div{
      border-top: 1px solid $light-color1;
      font-size: 1.5rem;
      padding-top: 10px;
      text-align: center;
    }
  }
}

.source{
  header+#comp-show-error-details{
    height: calc(100vh - $header-height);
  }
}

.source-enho, .source-enho-mobile{
  header+#comp-show-error-details{
    height: calc(100vh - $header-height - $footer-height);
  }
}

/* *********************** *********************** *********************** *
 * Web View
 * *********************** *********************** *********************** */

.web-view {
  header+#comp-show-error-details{
    height: calc(100vh - $header-height);
  }
}

.source-enho-web{
  header+#comp-show-error-details{
    height: 100vh;
    margin-top: $web-header-height;
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme{
  #comp-show-error-details{
    background-color: $dark-bg2;
  }
}