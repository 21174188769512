.sp{
  &__gct-survey__info-popup {
    .e_dialog__body {
      .survey-container {
        padding: 20px;
        .survey-questionair-container {
          padding-bottom: 20px;
          .survey-questionair {
            font-size: 16px;
            font-weight: 500;
            color:#545456;
          }
          .survey-option-container {
            margin-top: 20px;
            .option-wrapper {
              height: 50px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              padding: 13px 15px;
              background: #F6F7FB;
              border-radius: 6px;
              margin-bottom: 16px;
              .bp__radio-checkbox__button {
                display: flex;
                justify-content: start;
                align-items: center;
              }
              span {
                font-size: 16px;
                font-weight: 500;
                padding-left: 8px;
                color:#545456;
              }
            }
          }
        }
        .survey-footer {
          width: 100%;
          &.mt-12 {
            margin-top: 12px;
          }
          button {
            border-radius: 8px;
            font-weight: 500;
            font-size: 16px;
            padding: 12px 24px;
            &.disable-survey-btn {
              background: #BABEC3;
              color: #6B7073;
              border: none;
            }
          }
        }
        .survey-status-wrapper {
          width: 100%;
          min-height: 310px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .loader-container {
            padding: 55px 0px 16px 0px;
            svg {
              height: 64px;
              width: 64px;
            }
          }
          .title {
            font-weight: 500;
            font-size: 16px;
            color: #545456;
            padding-bottom: 6px;
            padding-top: 8px;
          }
          .description {
            font-size: 12px;
            font-weight: 400;
            padding-bottom: 90px;
            text-align: center;
            color:#545456;
          }
        }
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme {
  .sp{
    &__gct-survey__info-popup {
      .e_dialog__body {
        .survey-container {
          .survey-questionair-container {
            .survey-questionair {
              color:#FFFFFF;
            }
            .survey-option-container {
              .option-wrapper {
                background: #333;
                span {
                  color:#FFFFFF;
                }
              }
            }
          }
          .survey-footer {
            button {
              &.disable-survey-btn {
                background: #6B7073;
                color: #A9A9AA;
              }
            }
          }
          .survey-status-wrapper {
            .loader-container {
              .icon-warning-v2 {
                svg path {
                  &#warningv2-path-1, &#warningv2-path-2 {
                    stroke: transparent;
                    fill: #FFC400;
                  }
                }
              }
            }
            .title {
              color: #FFFFFF;
            }
            .description {
              color:#FFFFFF;
            }
          }
        }
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Media Queries
 * *********************** *********************** *********************** */

@media (width >= 768px) {
  .web-view {
    .bp__dialog {
      .sp {
        &__gct-survey__info-popup {
          .e_dialog__header {
            padding: 20px;
            border-radius: 0px;
            .bp__icon {
              height: 18px;
              width: 18px;
            }
          }
          .e_dialog__wrapper {
            width: 672px;
            border-radius: 0px;
            .e_dialog_title {
              font-size: 20px;
            }
            .survey-container {
              .survey-questionair-container .survey-questionair {
                font-size: 20px;
              }
              .survey-option-container .option-wrapper {
                height: 54px;
                .survey-radio-label {
                  font-size: 20px;
                }
              }
              .survey-footer {
                display: flex;
                justify-content: center;
                margin: 0;
                button {
                  width: 70%;
                  height: 60px;
                  font-size: 20px;
                }
              }
              .survey-status-wrapper {
                .loader-container {
                  svg {
                    height: 80px;
                    width: 80px;
                  }
                }
                .title {
                  font-size: 20px;
                  padding-bottom: 16px;
                }
                .description {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}