@import './variable';

.atom-design {
  /* ----------------------------------------------------------------------- *
  *  Text                                              Vertical-Center-Right
  *  Value
  *  ----------------------------------------------------------------------- */
  &--t1 {
    display: grid;
    gap: 0 14px;
    grid-template-columns: auto 45px;

    .com__ad {
      &__title {
        align-items: center;
        color: $light-color2;
        display: flex;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        word-break: break-word;

        >div:last-child {
          margin-left: 5px;
        }
      }

      &__action {
        font-size: 12px;
        font-weight: 500;
        grid-column: 2;
        grid-row: 1;
        line-height: 18px;
        place-self: center flex-end;
        @extend %color-blue;
      }

      &__value {
        color: $light-color2;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
      }
    }
  }

  /* ----------------------------------------------------------------------- *
  *  Vertical-Center-Left    Text
  *                          Value
  *  ----------------------------------------------------------------------- */

  &--t2 {
    align-items: center;
    display: grid;
    gap: 0 14px;
    grid-template-columns: 25px auto;
    margin-top: 16px;

    .com__ad {
      &__icon {
        grid-row: 1/3;
      }

      &__title {
        align-items: center;
        color: $light-color2;
        display: flex;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;

        >div:last-child {
          margin-left: 5px;
        }
      }

      &__value {
        color: $light-color2;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
      }
    }
  }

  /* ----------------------------------------------------------------------- *
  *  Vertical-Center-Left   Text                                      Action
  *  ----------------------------------------------------------------------- */
  
  &--t3 {
    align-items: center;
    display: grid;
    gap: 0 14px;
    grid-template-columns: 25px auto auto;
    margin-top: 16px;

    .com__ad {
      &__icon {
        grid-row: 1/2;
      }

      &__details {
        align-items: center;
        display: flex;
      }

      &__action {
        color: $color-blue;
        font-size: 12px;
        font-weight: 500;
        justify-self: flex-end;
        line-height: 15px;

        &--enabled{
          color: $color-orange;
        }

        &--disabled{
          color: $light-color4;
        }
      }

      &--title {
        align-items: center;
        color: $light-color2;
        display: flex;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
      }

      &--info {
        margin-left: 5px;
      }

      &--sub-title {
        color: $light-color2;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme {
  .atom-design {
    &--t1 {
      .com__ad {
        &__title {
          color: $dark-color1;
        }

        &__value {
          color: $dark-color1;
        }
      }
    }

    &--t2 {
      .com__ad {
        &__title {
          color: $dark-color2;
        }

        &__value {
          color: $dark-color1;
        }
      }
    }

    &--t3 {
      .com__ad {
        &__title {
          color: $dark-color2;
        }

        &--sub-title {
          color: $dark-bg3;
        }
      }
    }
  }
}


/* ----------------------------------------------------------------*
Orange theme
*---------------------------------------------------------------- */


.orange-theme {
  .atom-design {
    &--t1 {
      .com__ad {
        &__action {
          color: $color-orange;
        }
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Web view
 * *********************** *********************** *********************** */

@media screen and (width >= 1024px) and (height <= 1310px) {
  .com__ad {
    &__value {
      font-size: 16px !important;
      margin-top: 4px;
    }
  }
}