@import '../../../../assets/styles/variable';

.bp__loader {
  background-color: $light-bg2;
  top: $header-height;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 115;

  .loader {
    &__wrapper {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
  }

  .bp__icon {
    background-color: $light-bg2;
    border-radius: 50%;
    padding: 0;

    svg {
      height: 60px;
      width: 60px;
    }
  }

  &--no-bg {
    background-color: transparent !important;

    .bp__icon {
      background-color: #ffffff4d;
    }
  }

  &--no-bg-loader {
    .bp__icon {
      background-color: rgb(0 0 0 / 0%);
    }
  }
}

.source-itk,
.source-itk-mobile {
  .bp__loader {
    top: $itk-header-height;
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme {
  .bp__loader {
    background-color: $dark-bg2;

    .bp__icon {
      background-color: $dark-bg2;
    }
  }
}

/* *********************** *********************** *********************** *
 * Web View
 * *********************** *********************** *********************** */
.source-enho-web {
  .bp__loader {
    top: $web-header-height;
  }
}
