@import './variable';
@import './extend';

.bp__action-button {
  border: 1px solid;
  color: $light-color1;
  cursor: pointer;
  font-family: $fonts-seq;
  font-size: 14px;
  height: 48px;
  outline: 0;
  padding: 10px 20px;
  width: auto;
  @extend %bg-blue;

  &.bp__ab{
    &--mobile-view {
      background: transparent;
      border: none;
      border-radius: 0 !important;
      color: $light-color2;
      margin: 0 !important;
      padding: 0;
      width: 50%;

      &.without-bg-border {
        font-size: 14px;
        font-weight: 500;
        margin: 0 !important;
      }
    }

    &--mobile-view-hr {
      background-color: transparent;
      border: none;
      border-radius: 0 !important;
      margin: 0 !important;
      width: 100%;
      @extend %color-blue;

      &:not(:first-child) {
        border-top: 1px solid rgb(123 135 147 / 30%);
      }

      &:last-child {
        color: $light-color2;
      }

      &:only-child {
        font-weight: 500;
        @extend %color-blue;
      }
    }

    &--round-corner {
      border-radius: 30px;
    }

    &--full-width {
      width: 100%;
    }

    &--auto-width {
      width: auto;
    }

    &--disabled {
      background-color: $button-bg-diabled !important;
      border-color: $disabled !important;
      color: $light-color1 !important;

      &.bp__ab--outline{
        background-color: transparent !important;
        color: $disabled !important;
      }
    }

    &--outline {
      background: transparent;
      @extend %border-blue;
      @extend %color-blue;
    }
  }

  &.without-bg-border {
    background: transparent;
    border: 0;
    font-size: 15px;
    font-weight: 500;
    margin: 10px 0 0 !important;
    @extend %color-blue;
  }

  &:not(:first-child) {
    margin-top: 15px;
  }
}

.bp__ab{
  &--text {
    display: inline-flex;
    text-decoration: underline;
    @extend %color-blue;

    a {
      color: inherit !important;
      display: inherit;
    }
  }

  &--text-icon {
    display: inline-flex;
    margin-left: 5px;
  }
}

button{
  &:disabled{
    &.bp__ab{
      &--outline{
        background-color: $button-bg-diabled !important;
        border-color: $disabled !important;
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme {
  .bp__action-button {
    border-color: transparent;

    &.bp__ab{
      &--mobile-view {
        color: $dark-color1;

        &.without-bg-border {
          @extend %color-blue;
        }
      }

      &--mobile-view-hr {
        &:last-child {
          color: $dark-color1;
        }
        
        &:only-child {
          @extend %color-blue;
        }
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */

 .orange-theme{
  .bp__action-button{
    &.bp__ab{
      &--outline {
        @extend %border-orange;
        @extend %color-orange;
      }

      &--mobile-view {
        &.without-bg-border {
          @extend %color-orange;
        }
      }

      &--mobile-view-hr {
        &:only-child {
          @extend %color-orange;
        }
      }
    }
  }
 }
