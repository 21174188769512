@import '../../../../assets/styles/variable';

.bp__card--ev-schedule{
  .bp__card--caption{
    margin-top: 4px;
  }

  .bp__card-view{
    cursor: pointer;

    .sp{
      &__profile{
        &:not(:first-child){
          border-top: 1px solid $light-border;
          margin-top: 16px;
          padding-top: 16px;
        }
      }
    }
  }

  .evs{
    &--ev-charger{
      &.atom-design--t3{
        margin: 0;
      }
    }
  }
}

.evs{
  &--ev-charger{
    &.atom-design--t3{
      gap: 0 10px;
      grid-template-columns: 24px auto auto;

      .com__ad__details{
        display: block;
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme {
  .bp__card--ev-schedule{
    .evs{
      &--ev-charger{
        &.atom-design--t3{
          svg g path {
            fill: $dark-color1; // TODO
            stroke: $dark-color1;
          }
        }
      }
    }

    .bp__card-view{
      .sp{
        &__profile{
          &:not(:first-child){
            border-color: $light-color6;
          }
        }
      }
    }
  }

  .evs{
    &--ev-charger{
      &.atom-design--t3{
        .com__ad__action{
          svg polygon{
            fill: $dark-color1;
            stroke: $dark-color1;
          }
        }
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Web View
 * *********************** *********************** *********************** */
 .web-view{
  .bp__card--ev-schedule {
    .sp__profile {
      .evs{
        &--ev-charger{
          &.atom-design--t3{
            padding: 32px;
          }
        }
      }

      &:not(:first-child){
        padding-top: 0;
      }

      &:last-child{
        margin: 0;
      }
    }
  }
 }