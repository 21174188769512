@import '../../../../assets/styles/variable';

.bp__page{
  background-color: $light-bg2;
  height: 100%;
  position: fixed;
  top: 0;
  transition: left .3s ease-in-out;
  width: 100vw;

  &--open{
    left: 0;
  }

  &--wrapper{
    height: 100%;
    overflow-y: auto;

    > div{
      padding: 20px 20px 0;

      &:last-child{
        padding-bottom: 20px;
      }
    }
  }

  &--with-action{
    display: flex;
    flex-flow: column;
    height: inherit;
    justify-content: space-between;
  }

  header+.bp__page--wrapper{
    height: calc(100% - $header-height);
  }

  .bp__page--wrapper{
    height: 100%
  }

  .bp__loader{
    .bp__icon{
      margin-bottom: $header-height;
    }
  }
}

.source-itk{
  .bp__page{
    header+.bp__page--wrapper{
      height: calc(100% - ($itk-header-height * 1px));
    }
  }
}

.source-enho-mobile{
  .bp__page{
    height: calc(100% - $footer-height);
  }
}

.source-itk-mobile{
  .bp__page{
    height: calc(100% - $footer-height);

    header+.bp__page--wrapper{
      height: calc(100% - ($itk-header-height * 1px));
    }
  }

  .bp__loader{
    .bp__icon{
      margin-bottom: $itk-header-height;
    }
  }
}

.source-enho-web{
  #bp-page-profile-details, #bp-page-edit-profile, #bp-page-battery-details {
    flex-direction: column;
    justify-content: initial;
  }
  
  header{
    &+.bp__page{
      &--wrapper{
        margin: $web-header-height auto 32px auto !important;

        .pd__profile-container{
          padding: 30px 0 !important;
        }
      }
    }
  }
}

.source-enlm-web, .source-enla-web, .source-itk-web{
  #bp-page-profile-details, #bp-page-edit-profile, #bp-page-battery-details, #bp-page-storm-guard, #enho-storm-guard {
    flex-direction: column;
    justify-content: initial;

    .bp__page--wrapper {
      margin: 0 auto;

      .pd__profile-container{
        padding: 32px 0;
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Web view
 * *********************** *********************** *********************** */

.web-view {
  .bp__page{
    header {
      position: fixed;
      width: 100vw;
      z-index: 1;

      &+div{
        margin-top: $header-height !important;
      }
    }

    &--wrapper{
      overflow-y: initial;

      > div{
        padding: 32px 0 0;

        &:last-child{
          padding-bottom: 32px;
        }
      }
    }
  }
}

.source-enho-web {
  .bp__page{
    header {
      position: fixed;
      width: 100vw;
      z-index: 1;

      &+div{
        margin-top: $web-header-height !important;
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */

.orange-theme{
  .bp__page{
    background-color: $light-bg2;
  }
}

.web-view{
  &.orange-theme{
    .bp__page{
      background-color: $light-bg2;
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme{
  .bp__page{
    background-color: $dark-bg2;
  }
}