@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/extend';

.bp_toggle-switch{
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  min-width: 40px;
  position: relative;
  text-align: center;

  .bp_ts_wrapper{
    position: absolute;
    right: 0;
  }

  .bp_ts_loading-icon{
    .bp_icon{
      height: 20px;
      width: 20px;
    }
  }

  .bp_ts_label{
    font-size: 12px;
    margin: 2px 1px 0 0;
  }

  &.bp_ts_on{
    .bp_ts_label{
      color: $light-color2;
      font-weight: 500;
    }
  }

  &.bp_ts_off{
    .bp_ts_label{
      color: #7a8692;
      font-weight: normal;
    }
  }

  &.bp_ts_disabled{
    .bp_ts_handler{
      opacity: .7 !important;
    }

    .bp_ts_label{
      color: #ababae;
      font-weight: 500;
    }
  }

  &.bp_ts_loading{
    .bp_ts_label{
      color: #7a8692;
      font-weight: normal;
    }
  }
}

.ts{
  &__status-info{
    align-items: center;
    display: grid;
    grid-auto-flow: column;
    width: fit-content;
  }

  &__status-icon{
    margin-right: 5px;

    svg{
      height: 15px;
      width: 15px;
    }

    .icon-clock-circular{
      path{
        @extend %fill-blue;
      }
    }
  }

  &__status-text{
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-transform: capitalize;
  }
}

/* *********************** *********************** *********************** *
 * Web View
 * *********************** *********************** *********************** */

 .web-view {
  .ts{
    &__status-text{
      font-size: 16px;
      line-height: 20px;
    }
  }
}

/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */

.orange-theme{
  .bp_toggle-switch.bp_ts_on{
    .bp_ts_wrapper{
      .bp_ts_handler{
        .react-switch-bg{ 
          background: $color-orange !important;
          opacity: 0.5;
        }

        .react-switch-handle{
          background: $color-orange !important;
        }
      }
    }
  }
}