@import '../../../assets/styles/variable';

#enho-storm-guard{
  background-color: $light-bg2;
  height: 100vh;
}

/* *********************** *********************** *********************** *
 * Web View
 * *********************** *********************** *********************** */

 .web-view {
  #enho-storm-guard {
    display: flex;
    justify-content: center;
    margin: auto;
    overflow: auto;

    .bp__page--wrapper {
      width: 1050px;
    } 
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

 .dark-theme{
  #enho-storm-guard{
    background-color: $dark-bg2;
  }
}

/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */

.orange-theme{
  #enho-storm-guard{
    background-color: $light-color1;
  }
}