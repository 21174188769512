@import '../../../../assets/styles/variable';

.energy-type {
  margin: auto;
  text-align: center;
  width: 75px !important;

  &__icon-with-details {
    align-items: center;
    display: flex;
    flex-direction: column;

    &__icon {
      margin-bottom: 4px;

      span {
        height: 40px;
        width: 40px
      }

    }

    &__details {
      &__value {
        font-size: 16px;
        font-weight: 500;
        margin: 0;

        &__unit {
          font-size: 12px;
          font-weight: 400;
          line-height: 1;
          margin-left: 4px;
        }
      }

      &__name,
      &__sub-title {
        color: $light-color2;
        font-size: 12px;
        margin: 0
      }
      
    }
  }
}


/****************************************************************
Dark theme
****************************************************************/

.dark-theme {
  .energy-type {
    &__icon-with-details {
      &__details {
        &__name,
        &__sub-title {
          color: $dark-bg3;
        }

      }
    }
  }
}