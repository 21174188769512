.lock__popup__wrapper {
    padding: 24px 16px;
    text-align: center;
    .lock__wrapper__icon {
      display: flex;
      justify-content: center;
    }
    .e_dialog__content {
      margin-top: 24px;
      p:first-child {
        font-weight: 500;
        font-size: 16px;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
  .web-view .bp__dialog .e_dialog--mobile-view {
    .lock__popup__wrapper {
      .e_dialog__content {
        p:first-child {
          font-size: 20px;
        }
      }
    }
  }
  