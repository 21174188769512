@import '../../../../assets/styles/variable';

#bp-page-battery-details {
  .bp__page--wrapper {
    padding: 20px;
  }
}

/* *********************** *********************** *********************** *
 * Web View
 * *********************** *********************** *********************** */

.web-view {
  #bp-page-battery-details {
    display: flex;
    height: calc(100vh - 32px);
    justify-content: center;
    margin: auto;
    overflow: auto;
  
    .bp__page--wrapper {
      padding: 32px 0;
      width: 1050px;
    }
  }

  .e_dialog.e_dialog__battery.dt-tou-alert {
    .e_dialog__footer__wrapper {
      flex-direction: column;
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme {
  #bp-page-profile-details, #bp-page-edit-profile {
    background-color: $dark-bg2; 
  }
}
