@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/extend';

.lazy-fallback{
  align-items: center;
  background-color: $light-bg2;
  display: grid;
  font-family: $fonts-seq;
  height: 100vh;
  justify-content: center;

  >div{
    font-size: 12px;
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-lazy-fallback{
  align-items: center;
  background-color: $dark-bg2;
  display: grid;
  font-family: $fonts-seq;
  height: 100vh;
  justify-content: center;

  >div{
    font-size: 12px;
  }
}